import { useEffect } from 'react';
import { useDeviceStore } from '../services/DeviceService';
import useRedirectToViewer from '../hooks/useRedirectToViewer';
import QRCode from 'react-qr-code';
import React from 'react';
import { isIos, isAndroid, isMobile } from 'mobile-device-detect';
import { openIOSARQuickLook, openAndroidQuickLook } from '../Components/utils/ar';
import { GlobalStyles } from '../common/styles';
import Logo from '../Components/Logo';
import Lekker from '../Components/Lekker';
import LekkerQR from '../Components/LekkerQR';
import Footer from '../Components/Footer';
import DesktopFooter from '../Components/DesktopFooter';
import {
  StyledMain,
  BackgroundImage,
  StyledArButton,
  StyledHeadphoneInfo,
  SurfaceInfo,
  StyledDeviceInfo,
  StyledTerms,
  DesktopMain,
  DesktopInfo,
  StyledDesktopTerms,
  DesktopDeviceInfo,
  DesktopInstagramInfo,
} from './styles';

function App() {
  const { device, init, initialized } = useDeviceStore();

  const initiateAR = useRedirectToViewer(); 

  useEffect(() => {
    init();
  }, []);

  if (!initialized) return 'Loading...';

  if (device.isMobile) initiateAR(false);

  return (
    <>
      <GlobalStyles />
      {!device.isMobile ? (
        <>
          <DesktopMain>
            <LekkerQR />
            <DesktopInfo>
              <p>
                <b>
                  Für die virtuelle Überraschung benötigen Sie ihr Handy oder Tablet. Scannen Sie damit einfach den
                  QR-Code.
                </b>
              </p>
              <br />
              <p>
                Anschließend Kamera auf eine passende Oberfläche (z.B. Tisch) ausrichten <br />
                Überraschung wird automatisch platziert <br />
                mit gedrücktem Finger umstellen / neu platzieren <br />
                mit 2 Fingern vergrößern, verkleinern oder drehen
              </p>
            </DesktopInfo>

            <DesktopDeviceInfo>
              <b>Info: </b>Augmented Reality funktioniert nur auf neueren Smartphones und Tablets.
            </DesktopDeviceInfo>
            <DesktopFooter />
          </DesktopMain>
          <Logo />
        </>
      ) : (
        <>
          <BackgroundImage />
          <StyledMain>
            <StyledArButton
              onClick={() => {
                initiateAR(true);
              }}
            >
              AR Starten
            </StyledArButton>
            <SurfaceInfo>
              Wähle eine passende Oberfläche – wie einen Tisch mit etwas darauf – um Objekte zu platzieren.
            </SurfaceInfo>
            <StyledDeviceInfo>
              <b>Info: </b>Augmented Reality funktioniert nur auf neueren Smartphones und Tablets.
            </StyledDeviceInfo>
            <Footer />
          </StyledMain>
          <Logo />
        </>
      )}
    </>
  );
}

export default App;
