import { useEffect } from 'react';
import { useDeviceStore } from '../services/DeviceService';
import useBaseUrl from './useBaseUrl';

export function constructARUrl(device, baseUrl, { androidModel, androidSound, iosModel }) {
  const androidFallback = `${baseUrl}?isFallback=true`;
  return device.isIOS
    ? `${baseUrl}${iosModel}`
    : `intent://arvr.google.com/scene-viewer/1.0?file=${baseUrl}${androidModel}&mode=ar_preferred${
        androidSound ? `&sound=${baseUrl}${androidSound}` : ''
      }#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${androidFallback};end;`;
}

export function triggerNativeAR(url) {
  return new Promise((resolve, reject) => {
    const a = document.createElement('a');
    a.rel = 'ar';
    a.href = url;

    a.appendChild(new Image());

    a.addEventListener('click', resolve);
    a.addEventListener('error', reject);

    a.click();
  });
}

export default function useRedirectToViewer() {
  const { device, initialized } = useDeviceStore();
  const baseUrl = useBaseUrl();

  const initiateAR = async ignoreFallback => {
    if (!initialized || !device.isMobile) return;

    const urlParams = new URLSearchParams(window.location.search);
    const fallbackParam = urlParams.get('isFallback');
    if (fallbackParam == 'true' && !ignoreFallback) return;

    const arUrl = constructARUrl(device, baseUrl, window.arConfig);
    try {
      await triggerNativeAR(arUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to initiate AR:', error);
    }
  };

  return initiateAR;
}
