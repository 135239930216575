import styled, { css } from 'styled-components'
import { colors } from '../../common/styles'
import { mediaMin } from '../../common/layout'

export const KarliContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 60%;
  top: 7%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const KarliImage = styled.img`
  height: 100%;
`

export const CTA = styled.div`
  position: absolute;
  right: 20vw;
  top: 4vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  ${mediaMin(
    'medium',
    css`
      top: 10vh;
      right: 25vw;
    `
  )}
`

export const CTAText = styled.p`
  color: ${colors.blue};
  font-size: 14px;
`

export const More = styled.span`
  font-style: italic;
`

