import { create } from 'zustand';
import { isMobile, isIOS, isAndroid } from 'mobile-device-detect';

export const useDeviceStore = create(set => ({
  device: {},
  initialized: false,

  init: () => {
    const device = { isMobile: isMobile || isAndroid, isIOS };
    set({ device, initialized: true });
  },
}));
