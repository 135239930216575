import React from 'react';
import { LekkerContainer, Lekker, CTA, CTAText, More, Arrow, ScanCTA, QRCodeWrapper } from './styles';

import QRCode from 'react-qr-code';
import QRBackground from '../../common/assets/QRBackground.png';

import useBaseUrl from '../../hooks/useBaseUrl';

const LekkerQR = () => {
  const baseUrl = useBaseUrl();

  return (
    <LekkerContainer>
      <QRCodeWrapper style={{ backgroundImage: `url(${QRBackground})` }}>
        <QRCode
          size={135}
          width="auto"
          style={{ width: 'auto', height: 'auto', margin: '32px', boxSizing: 'border-box' }}
          value={baseUrl}
          viewBox={`0 0 256 256`}
          fgColor="#4a2871"
          bgColor="#f8ebf6"
        />
      </QRCodeWrapper>
    </LekkerContainer>
  );
};

export default LekkerQR;
