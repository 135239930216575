import React from 'react'
import { StyledLogo, StyledSvg } from './styles'
import logo from '../../common/assets/Logo.png';

const Logo = () => {
  return (
    <StyledLogo href="https://www.lekker.de/" target="_blank" rel="noopener noreferrer">
      <StyledSvg src={logo} />
    </StyledLogo>
  )
}

export default Logo;
