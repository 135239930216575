import styled from 'styled-components'
import { colors } from '../../common/styles'

export const LekkerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`

export const QRCodeWrapper = styled.div`

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 512px; 
  height: 512px;
`


export const CTA = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  right: 35%;
  top: 17%;
`

export const ScanCTA = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  left: 26%;
  bottom: 20%;
`

export const CTAText = styled.p`
  color: ${colors.blue};
  font-size: 14px;
`

export const More = styled.span`
  font-style: italic;
`

export const Arrow = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 20px;
  transform: rotate(${({ rotation }) => rotation}deg);
`
