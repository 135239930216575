import { createGlobalStyle } from 'styled-components'
import normalize from 'styled-normalize'
import RobotoWoff2 from '../Fonts/roboto-regular.woff2'
import RobotoBoldWoff2 from '../Fonts/roboto-bold.woff2'
import RobotoBoldItalicWoff2 from '../Fonts/roboto-bold-italic.woff2'

export const colors = {
  blue: '#2970BB',
  white: '#ffffff',
  grey: '#c0c0c0',
  darkGrey: '#454545',
  red: '#d42637',
}

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: regular;
    src: url(${RobotoWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 500;
    font-style: italic;
    src: url(${RobotoBoldItalicWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: bold;
    src: url(${RobotoBoldWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    src: url(${RobotoWoff2}) format('woff2');
  }


  ${normalize}
  body {
    color: ${colors.grey};
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }
`