import React from 'react';
import { StyledFooter, StyledFooterLink } from './styles';

const Footer = () => {
  return (
    <StyledFooter>
      <StyledFooterLink href={'https://www.lekker.de/impressum'} target="_blank" rel="noopener noreferrer">
        Impressum
      </StyledFooterLink>
      <span>|</span>
      <StyledFooterLink href={'https://www.lekker.de/datenschutz'} target="_blank" rel="noopener noreferrer">
        Datenschutz
      </StyledFooterLink>
    </StyledFooter>
  );
};

export default Footer;
