import styled from 'styled-components'

export const StyledLogo = styled.a`
  position: absolute;
  top: 32px;
  left: 36px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`
export const StyledSvg = styled.img`
  width: 118px;
  cursor: pointer;
`;